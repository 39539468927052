import { json } from "body-parser"

/**
 * 缓存方法
 */
const getSession = (key) => {
    let newKey = key || ''
    return JSON.parse(sessionStorage.getItem(newKey)) || ''
}

const setSession = (key, value) => {
    let newValue = JSON.stringify(value)
    sessionStorage.setItem(key, newValue)
}
export {
    getSession,
    setSession
} 